import { get, post } from "../utils/http"
import { baseUrls } from '../utils/urls'
const aps1 = baseUrls.aps1
// 排程记录分页查询 & 获取基准排程和对比排程 & 获取排序
export function getSchedulingData(page, size, scheduledStatus = '') {
    return get(`${aps1}/scheduledRecord/page?page=${page}&size=${size}&scheduledStatus=${scheduledStatus}`)
}

// 排程记录--排程流水号排序
export function getSerialNumberSortValue(page, size, scheduledStatus, serialNumberSortValue) {
    return get(`${aps1}/scheduledRecord/page?page=${page}&size=${size}&scheduledStatus=${scheduledStatus}&serialNumberSortValue=${serialNumberSortValue}`)
}

// 排程记录--创建时间排序
export function getCreateTimeSortValue(page, size, scheduledStatus, createTimeSortValue) {
    return get(`${aps1}/scheduledRecord/page?page=${page}&size=${size}&scheduledStatus=${scheduledStatus}&createTimeSortValue=${createTimeSortValue}`)
}

// 排程记录--创建人排序
export function getCreateUserSortValue(page, size, scheduledStatus, createUserSortValue) {
    return get(`${aps1}/scheduledRecord/page?page=${page}&size=${size}&scheduledStatus=${scheduledStatus}&createUserSortValue=${createUserSortValue}`)
}

// 排程记录--计划分析排序
export function getPlanAnalysisStateSortValue(page, size, scheduledStatus, planAnalysisStateSortValue) {
    return get(`${aps1}/scheduledRecord/page?page=${page}&size=${size}&scheduledStatus=${scheduledStatus}&planAnalysisStateSortValue=${planAnalysisStateSortValue}`)
}

// 排程记录--计划分析--勾选项参与分析
export function updateSelected(params) {
    return post(`${aps1}/scheduledRecord/updateById`, params)
}

// 计划分析--全选
export function allSelected(scheduledStatus,isSelectedAll){
    return get(`${aps1}/scheduledRecord/updateAllPlanAnalysisSelectState?scheduledStatus=${scheduledStatus}&isSelectedAll=${isSelectedAll}`)
}

// 排程计划发布
export function scheduledPlanRelease(serialNumber) {
    return post(`${aps1}/scheduledRecord/scheduledPlanRelease`, serialNumber)
}

// 排程记录分页查询--搜索
export function searchSchedulingData(searchValue) {
    return get(`${aps1}/scheduledRecord/page?searchValue=${searchValue}`)
}

// 主页排程记录查询（全部数据）
export function allData(searchValue) {
    return get(`${aps1}/scheduledRecord/list?searchValue=${searchValue}`)
}

// 排程记录--通过ID批量删除
export function delScheduling(integers) {
    return post(`${aps1}/scheduledRecord/deleteByIds`, integers)
}

// 排程记录--导出
export function deriveData(page, size, baseSerialNumber, comparedSerialNumber, analysisType, comparisonCondition) {
    return get(`${aps1}/planAnalysisRecord/export?page=${page}&size=${size}&baseSerialNumber=${baseSerialNumber}&comparedSerialNumber=${comparedSerialNumber}&analysisType=${analysisType}&comparisonCondition=${comparisonCondition}`)
}

// 计划分析--查看报告
export function getAnalysls(page, size, baseSerialNumber, comparedSerialNumber, analysisType, comparisonCondition) {
    return get(`${aps1}/planAnalysisRecord/page?page=${page}&size=${size}&baseSerialNumber=${baseSerialNumber}&comparedSerialNumber=${comparedSerialNumber}&analysisType=${analysisType}&comparisonCondition=${comparisonCondition}`)
}

// 计划分析--齐料分析列表
export function getBom1Data(page, size) {
    return get(`${aps1}/materialAnalysisRecord/page?page=${page}&size=${size}`)
}

// 计划分析--齐料分析列表--搜索
export function searchBom1Data(page, size, searchValue) {
    return get(`${aps1}/materialAnalysisRecord/page?page=${page}&size=${size}&searchValue=${searchValue}`)
}

// 计划分析--齐料分析欠料表
export function getBom2Data(page, size) {
    return get(`${aps1}/materialShortage/page?page=${page}&size=${size}`)
}

// 计划分析--齐料分析欠料表--搜索
export function searchBom2Data(page, size, searchValue) {
    return get(`${aps1}/materialShortage/page?page=${page}&size=${size}&searchValue=${searchValue}`)
}

// 欠料表--导出
export function deriveBom2Data(ids) {
    return get(`${aps1}/reportform/export/materialShortageInfo?ids=${ids}`)
}

// 排程失败，日志下载
export function errorLog(serialNumber) {
    return get(`${aps1}/scheduledRecord/exportScheduledLog?serialNumber=${serialNumber}`)
}