import { get, post } from "../utils/http"
import { baseUrls } from '../utils/urls'
const aps1 = baseUrls.aps1

// 生产订单列表
export function getProductionData(page, size) {
    return get(`${aps1}/productionOrder/page?page=${page}&size=${size}`)
}

// 生产订单列表--搜索
export function searchProductionData(page, size, searchValue) {
    return get(`${aps1}/productionOrder/page?page=${page}&size=${size}&searchValue=${searchValue}`)
}

// 导入生产订单
export function importProduction(params) {
    return post(`${aps1}/excel/importProductionOrder`, params)
}

// 导入宜搭订单
export function importOrder(page, size) {
    return get(`${aps1}/productionOrder/importOrder?page=${page}&size=${size}`)
}

// 散件
export function updateById(params) {
    return post(`${aps1}/productionOrder/updateById`, params)
}

// 合并
export function incorporateOrder(params) {
    return post(`${aps1}/productionOrder/incorporateOrder`, params)
}

// 拆分
export function splitOrder(params) {
    return post(`${aps1}/productionOrder/splitOrder`, params)
}

// 销售订单列表
export function getSaleData(page, size) {
    return get(`${aps1}/salesOrder/page?page=${page}&size=${size}`)
}

// 销售订单--更新工单
export function updateOrder(ids, isAllSelected) {
    return get(`${aps1}/salesOrder/updateAssemblyAndProductOrder?ids=${ids}&isAllSelected=${isAllSelected}`)
}

// 销售订单列表--搜索
export function searchSaleData(page, size, searchValue) {
    return get(`${aps1}/salesOrder/page?page=${page}&size=${size}&searchValue=${searchValue}`)
}

// 导入销售订单
export function importSale(params) {
    return post(`${aps1}/excel/importSalesOrder`, params)
}

// 导入宜搭--销售订单
export function importOrder2(page, size) {
    return get(`${aps1}/salesOrder/importOrder?page=${page}&size=${size}`)
}

// 合并--销售订单
export function incorporateOrder2(params) {
    return post(`${aps1}/salesOrder/incorporateOrder`, params)
}

// 拆分--销售订单
export function splitOrder2(params) {
    return post(`${aps1}/salesOrder/splitOrder`, params)
}

// 导出--生产工单
export function export1() {
    return get(`${aps1}/reportform/export/workOrderDeliveryPlan`)
}

// 导出--销售订单
export function export2() {
    return get(`${aps1}/salesOrder/export`)
}

// 更新工单查看原因--销售订单
export function updateReason() {
    return get(`${aps1}/salesOrder/getUpdateLog`)
}

// 装配工单列表
export function getAssemblyOrder(page, size) {
    return get(`${aps1}/assemblyOrder/page?page=${page}&size=${size}`)
}
// 装配工单列表--搜索
export function searchAssemblyOrder(page, size, searchValue) {
    return get(`${aps1}/assemblyOrder/page?page=${page}&size=${size}&searchValue=${searchValue}`)
}

// 导入装配工单
export function importAssemblyOrder(params) {
    return post(`${aps1}/excel/importAssemblyOrder`, params)
}
// 导入宜搭--装配工单
// export function importAssemblyOrder(page, size) {
//     return get(`${aps1}/productionAssemblyOrder/importAssemblyOrder?page=${page}&size=${size}`)
// }
// 合并--装配工单
export function incorporateAssemblyOrder(params) {
    return post(`${aps1}/assemblyOrder/incorporateOrder`, params)
}
// 拆分--装配工单
export function splitAssemblyOrder(params) {
    return post(`${aps1}/assemblyOrder/splitOrder`, params)
}
// 导出--装配工单
export function exportAssemblyOrder() {
    return get(`${aps1}/assemblyOrder/export`)
}