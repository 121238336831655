import { get, post, getBlob } from '../utils/http';
import { baseUrls } from '../utils/urls';
const aps1 = baseUrls.aps1;
const qs = require('qs');

//获取设备甘特图数据
export function getDeviceGantt(params) {
  return get(`${aps1}/ganttChart/getDeviceGantt?${qs.stringify(params)}`);
}
//拖拽甘特图验证
export function dragProcedure(params) {
  return post(`${aps1}/ganttChart/dragProcedure`, params);
}
//保存排产数据
export function saveGantt(params) {
  return get(`${aps1}/ganttChart/saveScheduledProcedures?${qs.stringify(params)}`);
}
//mes同步
export function getMesSync() {
  return get(`${aps1}/ganttChart/getProductionProgressData`);
}
//设备甘特图排程
export function deviceGanttSchedule(params) {
  return post(`${aps1}/ganttChart/deviceGanttSchedule`, params);
}
//通过排产号查询详情
export function findBySchedulingNo(params) {
  return get(`${aps1}/ganttChart/findBySchedulingNo?${qs.stringify(params)}`);
}
//修改工序详情
export function updateProcedure(params) {
  return post(`${aps1}/ganttChart/updateProcedure`, params);
}
//获取工序连线
export function getProcedure(params) {
  return get(`${aps1}/ganttChart/getProcedureByProductionNo?${qs.stringify(params)}`);
}
//取消保存排产数据 (情况临时流水号数据)
export function cancelSave() {
  return get(`${aps1}/ganttChart/cancelSaveScheduledProcedures?`);
}

//获取工艺甘特图数据
export function getProcedureGantt(params) {
  return get(`${aps1}/ganttChart/getProcedureGantt?${qs.stringify(params)}`);
}
//获取日历甘特图数据
export function getCalendarGantt(params) {
  return get(`${aps1}/ganttChart/getCalendarGantt?${qs.stringify(params)}`);
}

//导入在制品同步
export function importSync(params) {
  return post(`${aps1}/excel/importProductionProgressData`, params);
}

//排程失败打印日志
export function exportScheduledLog(serialNumber) {
  return getBlob(`${aps1}/scheduledRecord/exportScheduledLog?serialNumber=${serialNumber}`);
}
//下载导入模板
export function downImportTemplete(templateParam) {
  return get(`${aps1}/excel/downImportTemplete?templeteType=${templateParam}`);
}

// 导出生产计划表
export function exportSchedulingPlan(serialNumber) {
  return getBlob(`${aps1}/reportform/export/schedulingPlan?serialNumber=${serialNumber}`)
}

