<template>
    <el-dialog title="" :visible.sync="isShowDialog" width="500px" custom-class="resultDialog dataM" center
        :before-close="handleClose">
        <div class="tip">
            <img :src="result ? successSrc : errorSrc" alt="">
            <div class="title">发布{{ message }}</div>
            <div class="subtitle" v-if="errorReason">排程流水号：{{ serialNum }}</div>
            <div class="subtitle" v-if="!errorReason">{{ info1 }}</div>
            <div class="subtitle" v-if="!errorReason">{{ info2 }}</div>
            <div class="subtitle" v-if="errorReason">原因：{{ errorReason }}</div>
            <div class="footer">
                <el-button @click="handleClose" v-if="errorReason">返回</el-button>
                <el-button @click="handleClose" v-if="!errorReason">取消</el-button>
                <el-button type="primary" @click="go" v-if="!errorReason">{{ plan }}</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            successSrc: require('@/assets/images/dataManage/success.png'),
            errorSrc: require('@/assets/images/dataManage/error.png'),
        }
    },
    props: {
        info: {
            type: String,
            default: ''
        },
        isShowDialog: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ''
        },
        result: {
            type: Boolean,
            default: true
        },
        serialNum: {
            type: String,
            default: ''
        },
        errorReason: {
            type: String,
            default: ''
        }
    },
    computed: {
        plan() {
            if (this.info.includes('更新生产计划')) {
                return '更新生产计划'
            } else {
                return '更新装配计划'
            }
        },
        info1() {
            return this.info.split('，')[0]
        },
        info2() {
            return this.info.split('，')[1]
        }
    },
    methods: {
        go(){
            this.$emit('go',this.plan)
            this.$emit('closePlanDialog')
        },
        handleClose() {
            this.$emit('closePlanDialog')
        }
    }
}
</script>

<style lang="scss" scoped>
.resultDialog {
    img {
        width: 70px;
        height: 70px;
    }

    .tip {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0px 23px 0px;

        div {
            line-height: 40px;
            color: #B9B9B9;
            font-size: 16px;
        }

        .title {
            font-size: 24px;
            margin-top: 15px;
            color: #FFFFFF;
        }

        .el-button {
            color: #fff;
        }
    }
}

.subtitle:nth-of-type(2) {
    margin-bottom: -12px;
}

.subtitle:nth-of-type(3) {
    margin-bottom: 5px;
}

.footer {
    margin-top: 15px;
}
</style>