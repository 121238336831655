export function getDay() {
  let time = new Date()
  let year = time.getFullYear()
  let month = time.getMonth() + 1
  let day = time.getDate()
  let hour = time.getHours()
  let minute = time.getMinutes()
  let second = time.getSeconds()
  if (String(minute).length == 1) {
    minute = '0' + minute
  }
  if (String(second).length == 1) {
    second = '0' + second
  }
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}